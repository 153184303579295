@font-face {
  font-family: 'La Belle Aurore';
  font-style: normal;
  font-weight: 400;
  src: local('La Belle Aurore'), local('LaBelleAurore'), url(https://fonts.gstatic.com/s/labelleaurore/v8/RrQIbot8-mNYKnGNDkWlocovHeI4HO2E.woff2) format('woff2');
}


/* You can add global styles to this file, and also import other style files */
element.style {
  height: 135px;
}
.control-label.required:after { 
  color: red;
  content: "*";
  position: absolute;
  margin-left: 5px;
  top:7px;
}

.error-msg {
  color: red;
}
 .has-error label, .text-danger{
  color:red!important;
  }
  .has-error input, .has-error button, .has-error button,  .has-error textarea, .has-error  .form-control{
  border:1px solid red !important;
  } 
 
  .has-error span {
    /* border:1px solid white !important; */
    margin: -1px;
  }
  .btn-teal {
    background-color: #2a7db5;
    color: #FFF !important;
}

.process-wizard-primary > .process-wizard-step > .process-wizard-dot, .process-wizard-primary > .process-wizard-step > .progress > .progress-bar {
  background: #7dc546;
}

.widget-content[_ngcontent-c4] {
  padding: 0px 10px 0px 10px !important;
 
}

  p-accordiontab.bg-success .p-accordion-header a {
    background: #7ac143 !important;
    border-top: 1px solid #7ac143 !important;
    border-color:#7ac143!important;
}

p-accordiontab.bg-info .ui-accordion-header {
  background: #17a2b8 !important;
  border-top: 1px solid #17a2b8 !important;
}

p-accordiontab.bg-primary .ui-accordion-header {
  background: #57a1d3 !important;
  border-top: 1px solid #57a1d3 !important;
}

p-accordiontab.bg-warning .ui-accordion-header {
  background: #ffc107 !important;
  border-top: 1px solid #ffc107 !important;
}

p-accordiontab.bg-success div a span, p-accordiontab.bg-primary div a span, p-accordiontab.bg-info div a span, p-accordiontab.bg-warning div a span {color:white !important;}

p-accordiontab.bg-success div a.ui-paginator-element span {color: #212121 !important}


section.dark.page-header {
  color: #fff;
  background-color: #2a7db5  !important;
}
.bg-dark {
  background-color:#2a7db5  !important;
}

.bg-cyan {background: #17adc2}
.bg-green {background: #7dc546}
.bg-magenta {background: #a43794}
.bg-yellow {background: #f4ae32}
.bg-lipurple {background: #794ee6}

.p-inputtext {
  display: block !important;
  width: 100%;
  height: 30px;
  font-size: 14px !important;
  line-height: 1.5 !important; 
  color: #495057 !important;
  background-color: #fff;
  background-image: none !important;
  background-clip: padding-box !important;
   border-radius: .25rem !important;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s !important;
  border: #c6c6c6 1px solid !important;
  box-shadow: none !important;
  border-radius: 3px !important; 
}
.card{
  overflow-y: visible !important;
}

#opportunityContactLog .ui-dialog.ui-widget .ui-dialog-content {
  min-height: 600px;
}


.ui-calendar {
  display: block !important;
}
/* 
.ui-dialog-footer {
  padding: 15px 20px 40px 20px !important;
}
 */

.ui-dialog.ui-widget .ui-dialog-titlebar {
  padding: 6px 6px 6px 20px !important; 
}

.fluDlgFooter  .ui-dialog-footer{
  padding: 15px 20px 20px 20px !important;
}


.ui-dialog.ui-widget .ui-dialog-titlebar .ui-dialog-title {
  font-size: 18px  !important; 
}

.form-control {
  padding: 0px 10px;
}


textarea.form-control{
  height: auto !important;
}


.has-error p-calender {
  border: none !important; 
}

.cardHeaderDGrayBg > .ui-accordion .ui-accordion-header {
  background-color: #333333 !important;
}

#accorAdvOpt > div,.ui-state-active:hover a,.ui-accordion-header a{
  background: #787878 !important;
  border: none !important;
}

#accorAdvOpt > div a, .ui-state-active:hover a, .ui-accordion-header a .ui-accordion-toggle-icon
{
  background: #787878 !important;
  border: none !important;
}

#accorAdvOpt  .p-accordion-content {
  background-color: #d8d8d8 !important;
  border: 1px solid  #333333 !important;
}


#accorAdvOpt > div a,.ui-state-active:hover a,.ui-accordion-header a .ui-accordion-toggle-icon{
  color: #fff !important;
}

#accorAdvOpt  .ui-accordion-header{
  margin-bottom: 0 !important;
}

#accorAdvOpt  .ui-accordion-content {
  background-color: #d8d8d8 !important;
  border: 1px solid  #333333 !important;
}


.bg-light{
  background-color:#edf0f3 !important;
}

/* IE10+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fancy-form > .fancy-arrow, .fancy-form > .fancy-arrow-double {
    display: none !important;
  }
}

.greenText {
  color: rgb(59, 164, 1);
}
.greyText{
color:#343a40;
}
.yellowText{
  color: rgb(254, 161, 2);
}
.redText{
    color: rgb(207, 0, 60);
}

.lightGreenBg{
  background-color: #d7ecc1;
}
.lightBlueBg{
  background-color: #c1dfec;
}
.lightYellowBg{
  background-color: #f2eebb;
}
.smallText {
  font-size:12px;
}
.lightBrownBG{
  background-color: #777777 !important;
  border-color: #616161 !important;
}
.lightDrkCreameBg {
  background-color: #f0debc;
}
.lightPinkBg {
  background-color: #f3d8d9;
}
.lightGrayBg {
  background-color: #eeeeee;
}
.cardHeaderBlueBg {
  background-color: #17adc2 !important;
}
button.cardHeaderBlueBg{
  color:white;
}
.cardBodyBlueBg {
  background-color: #b2e4eb !important;
}
.cardHeaderGreenBg {
  background-color: #7dc546 !important;
}

.cardHeaderGreenBgDisabled {
  background-color: #7dc546 !important;
  color:#fff !important;
  opacity: 0.5 !important;
}

.cardBodyGreenBg {
  background-color: #d4ecc1 !important;
}
.cardHeaderVioletBg {
  background-color: #a43794 !important;
}
.cardHeaderVioletBgBtn {
  background-color: #882979 !important;
}

.btnVioletBg {
  background-color: #a43794 !important;
  border-color: #a43794 !important;
}


a.activeVioletBg, .activeVioletBg{
  background-color: #a43794 !important;
text-align: center;
  color:#ffffff !important;
}

.activeVioletBg.ui-state-highlight{
text-align: center;
  background-color: #882979  !important;
} 

.activeYellowBg.ui-state-highlight{
  background-color: #de8903  !important;
text-align: center;
}



#corporateSchedulerTabs table th, a.activeBlueBg, .activeBlueBg{
  background-color: #7dc546 !important;
  color:#ffffff !important;
}

#corporateSchedulerTabs .ui-paginator .ui-paginator-page.ui-state-active {
  background: #7dc546 !important;
  color: #ffffff;
  border-color: #7dc546 !important;
}

#corporateSchedulerTabs  p-calendar button span{
  color:#fff !important
}

#corporateSchedulerTabs  .ui-tabview .ui-tabview-nav > li.ui-state-active {
  background: #ffffff;
  font-weight: bold;
  color: #2a7db5 !important;
  border-top: 3px solid #2a7db5!important;
}



a.activeYellowBg, .activeYellowBg{
  background-color: #f4ae32 !important;
text-align: center;
  color:#ffffff !important;
}

#Clinics .ui-tabview .ui-tabview-nav > li.ui-state-active {
  background: #ffffff;
  font-weight: bold;
  color: #a43794 !important;
  border-top:3px solid #a43794 !important;
}
.ui-tabview .ui-tabview-nav > li.ui-state-active,.ui-tabview.ui-tabview-top>.ui-tabview-nav li{
  height: 32px;
}
#LocalOppourtunies .ui-tabview .ui-tabview-nav > li.ui-state-active {
  background: #ffffff;
  font-weight: bold;
  color: #f4ae32 !important;
  border-top:3px solid #f4ae32 !important;
}

/* dashboard css for prime ng upgrade start*/
#Clinics .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link
{
  background: #ffffff;
  font-weight: bold;
  border-color: #bebebe;
  color: #a43794 !important;
  border-top:3px solid #a43794 !important;
  height: 32px;
}


#LocalOppourtunies .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link
{
  background: #ffffff;
  font-weight: bold;
  border-color: #bebebe;
  color: #f4ae32 !important;
  border-top:3px solid #f4ae32 !important;
  height: 32px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link{
  height: 32px;
}

/* dashboard css for prime ng upgrade end*/


#Clinics .ui-datatable th.ui-state-default, #Clinics .ui-column-title {
  background-color: #a43794 ;
  color:white;
}
#Clinics .ui-paginator .ui-paginator-page.ui-state-active {
  background: #a43794 !important;
  color: #ffffff !important;
  border-color: #a43794 !important;
}

#Clinics .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  background: #a43794 !important;
  color: #ffffff !important;
  border-color: #a43794 !important;
}

#LocalOppourtunies .ui-datatable th.ui-state-default, #LocalOppourtunies .ui-column-title {
  background-color: #f4ae32;
  color:white;
}
#LocalOppourtunies  .ui-paginator .ui-paginator-page.ui-state-active {
  background: #f4ae32 !important;
  color: #ffffff !important;
  border-color: #f4ae32 !important;
}

#LocalOppourtunies .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  background: #f4ae32 !important;
  color: #ffffff !important;
  border-color: #f4ae32 !important;
}

#LocalOppourtunies  .ui-paginator, #Clinics  .ui-paginator {
  text-align: left !important;
}

#LocalOppourtunies table, #Clinics table {
  margin-bottom: 0px !important;
}

.activeTabPrimeNg{
  border-top:3px solid #a43794 !important;
}
.activeTabYellowPrimeNg{
  border-top:3px solid #f4ae32 !important;
}
.cardBodyVioletBg {
  background-color: #e1bcdb !important;
}
.cardHeaderPurpleBg {
  background-color: #794ee6 !important;
}
.cardHeaderPurpleBgBtn {
  background-color: #4c0fe4 !important;
}
.cardBodyPurpleBg {
  background-color: #d3c3e9 !important;
}
.cardHeaderYellowBg {
  background-color: #f4ae32 !important;
}
.cardHeaderYellowBgBtn {
  background-color: #de8903 !important;
}
.cardBodyYellowBg {
  background-color: #fbe4bb !important;
}
.cardHeaderOrangeBg {
  background-color: #f46d32 !important;
}
.cardBodyOrangeBg {
  background-color: #fbd7bb !important;
}

.cardHeaderDBlueBg {
  background-color: #2a7db5 !important;
}
.cardBodyDBlueBg {
  background-color: #bed3e6 !important;
}
.cardHeaderDGrayBg{
  background-color: #787878 !important;
}
.cardBodyDGrayBg{
  background-color: #eeeeee !important;
}
.cardBodyDBlueBg label, .cardBodyDBlueBg span, .cardBodyYellowBg label, .cardBodyYellowBg span, .cardBodyVioletBg label, .cardBodyVioletBg span, .cardBodyGreenBg label, .cardBodyGreenBg span, .cardBodyBlueBg label, .cardBodyBlueBg span{
  color: #333333;
}
.bdrBtmOnly{
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.whiteBG{
  background-color:white;
}


.BulkActionSpan {
  position: absolute;
  bottom: -88px;
  right: 8px;
  text-align: right;
}
.ui-inputtext{
  padding: 0px 10px !important;
  font-size: 14px !important;
}

.ui-dropdown-label.ui-inputtext.ui-corner-all{
  padding: .25em !important;
  font-size: 14px !important;
}

.ui-dropdown-trigger.ui-state-default.ui-corner-right{
  border: #c6c6c6 1px solid !important;
  right:-27px;
 height:30px;
}
.hintModal
{
  background: #2a7db5;
  color:white;
}



.hintConfBtn {
  border: 1px solid #cf1737;
  background: #cf1737 !important;
}

.hintConfBtn:hover {
  border: 1px solid #cf1737  !important;
}

#HintDlg .btn-primary:not([disabled]):not(.disabled):active, .firstHintDlg   .btn-primary:not([disabled]):not(.disabled):active{
  background-color: #cf1737 !important;
  border-color: #cf1737  !important;
}

.hintConfBtn:not( :hover ){ border: 1px solid #cf1737  !important; }


#HintDlg .ui-widget-header,  .firstHintDlg .ui-widget-header {
  background: transparent !important;
  height: 0px  !important;
}

#HintDlg .hintBtn, .firstHintDlg  .hintBtn{
  background: #cf1737 !important;
    color: white;
    position: relative;
    top: -32px;
    left: 30px;
    font-weight: 900;
    font-size: 20px;
    padding: 1px 10px;
    border-radius: 30px;
    border: 1px solid #cf1737;
}
#HintDlg .ui-dialog .ui-dialog-titlebar-close span, .firstHintDlg .ui-dialog .ui-dialog-titlebar-close span{
  background: #cf1737 !important;
  color: white;
  padding:5px;
  font-size: 20px;
  border-radius: 30px;
  border: 1px solid #cf1737;
  position: absolute;
  top: -10px;
  right: -14px;
  z-index: 1;
}

/* .p-dialog .p-dialog-header
{
background: #fff;
} */

/* hint pop up css for prime ng upgrade start */
#HintDlg .p-dialog-header-icons button
{
  background: #cf1737 !important;
  color: white !important; 
  padding:5px;
  font-size: 20px;
  border-radius: 30px;
  border: 1px solid #cf1737;
  position: absolute;
  top: -10px;
  right: -14px;
  z-index: 1;
}

#HintDlg .p-dialog-header-icons button span
{
  color: white !important;
}

/* hint pop up css for prime ng upgrade end */
#HintDlg .ui-dialog, .firstHintDlg  .ui-dialog{
  overflow: visible !important;
  border-top: #cf1737 4px solid;
  -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
#HintDlg .fa-fw,  .firstHintDlg .fa-fw {
  width: 30px !important;
    padding: 4px;

}
#HintDlg .ui-dialog .ui-dialog-titlebar-close, .firstHintDlg  .ui-dialog .ui-dialog-titlebar-close{
  float: none important;
  }

#myModal3 .modal-content {
  border-top-color: #cf1737;
}



#myModal4 .hintModal
{
  background: #505050;
  color:white;
}

#myModal4 .modal-content {
  border-top-color: #505050;
}

#ipNatConDNC p-dtcheckbox div.ui-chkbox-box.ui-state-active {
  color:#000 !important;
}

#ipNatConDNC p-dtcheckbox:first-child {
  display: none !important;
}

.ipNationalCntSearch{
 border-color:lightgrey;width:86%; border-style:solid; border-width:0.5px; font-family:Arial, Helvetica, sans-serif;}

 .pointerFa  i.fa-trash {
   cursor: pointer;
 }

 .ui-datatable tbody > tr.ui-widget-content.ui-state-highlight {
  background-color: transparent !important;
  color: #222222 !important;
}

 
#popOnSucc .fa-close, #dialgFollowUp  .fa-close, p-dialog[header="Send Invoice To:"] .fa-close{
  display: none !important;

}

#hintCheckBox .ui-inputswitch-checked {
  border-color: #cf1737 !important;
  background: #cf1737  !important;
  color: #ffffff !important;
}

#hintCheckBox .ui-widget {
  cursor: pointer !important;
  display: inline-block !important;
  position: relative !important;
  height: 28px !important;
  width: 60px !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 20px !important;
  text-align: center !important;
  background: #cf1737 !important;
  border: 2px solid #cf1737 !important;
  text-transform: uppercase !important;
  font-family: Helvetica, Arial, sans-serif !important;
  -webkit-transition: 0.3s ease-out !important;
  -moz-transition: 0.3s ease-out !important;
  -o-transition: 0.3s ease-out !important;
  transition: 0.3s ease-out !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -o-border-radius: 2px !important;
  border-radius: 2px !important;
}

 #hintCheckBox .ui-inputswitch-checked {
  border: 2px solid #cf1737 !important;
} 

ngb-typeahead-window {
  left:0px !important;
  max-height: 420px;
  overflow: hidden;
  overflow-y: auto;
  max-width: 561px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.printMrgnOrpdng{
  display: none !important;
}

.txt10{
  font-size: 12px;
  line-height: 1px;
}

.undLink{
  text-decoration: underline !important;
}

.w30{
  width:30px;
}
.w90{
  width:240px;
}
.w91{
  width:85px;
}
#HintDlg .ui-dialog{
  position: absolute;
  top:100px !important;
  } 


  .list-group-item {
    padding: 5px 10px !important;
  }

  .noBg{
    background-color:transparent !important;
  }



  .inTbl  .btn {
    height: 24px !important;
    font-size: 10px !important;
    padding: 4px 4px  !important;
}

.border-0  thead th, .border-0  tbody td{
  border-bottom: none !important;
  border-top: none !important;
}


.bdr2{
  border-radius: 8px !important;
}

.blueText {
  color:#2a7db5  !important
}

.h-340 {
  height: 340px !important;
}

.mh300 {
  min-height: 300px;
  height: 300px;
  max-height: 300px;
}

.f24b {
  font-size: 24px !important;
  font-weight: bold;
}
.f18r{
  font-size: 18px !important;
}

input::-ms-clear, input::-ms-reveal, ::-ms-clear, ::-ms-reveal {
  display: none !important;
}

.elipsisText{
  word-break: break-all;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: table-cell;
  margin-bottom: 20px;
}

.column3{
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
  }
  
  .column3 ul li{
    line-height: 30px;
  }

  textarea.form-control-plaintext{
    resize: unset !important; 
    overflow-y:hidden;
  }

  .form-group label {
    margin-bottom:2px;
  }

  .form-group input {
    margin-bottom:10px;
  }

   .searchFlow .ui-dialog-content,.searchFlow .ui-dialog{
    overflow: visible !important;
  }

  .minDlg{
   width:260px !important;
  }

  .minDlg500{
    width:500px;
   }

  .row{
    margin-bottom:20px;
  }

  .form-control-clear {
    z-index: 10;
    pointer-events: auto;
    cursor: pointer;
  }

  .clFld i.fa-close{
    position: absolute;
    right: 8px;
    z-index: 999;
    cursor: pointer;
    top: 8px;
  }
  select{
    cursor: pointer;
  }

  .maxWidth{
    max-width: 500px;
  }

  .ui-chkbox-box.ui-state-active, .ui-radiobutton-box.ui-state-active {
     border: 1px solid #222222 !important; 
     background: unset !important;
}

.noBg{
  background: transparent !important;
  padding-left:0px !important;
  border:0 !important;
}

.ui-paginator {
  padding: 10px !important;
}

.p-paginator {
  padding: 10px !important;
}

.oSearch{
  z-index: 9999;
}

.histLog tbody tr td{
  border-top: 1px solid#787878 !important;
  vertical-align: top !important;
}



#contractCompConf .ui-dialog.ui-widget .ui-dialog-titlebar .ui-dialog-title{
  color:#b92c28 !important;
}


#contractCompConf  .ui-confirmdialog.ui-dialog .ui-dialog-content {
  max-height: 400px !important;
}

.dropdown-menu {
  min-width: 190px !important;
}

.w500{
  max-width: 500px;
}


.bold{
  font-weight: bold !important;
}
.mnH145{
  min-height: 145px !important;
}
.mnH50{
  min-height: 52px !important;
}

.vrySmFnt{
  font-size: 10px;
  display: block;
  margin-top: -12px;
}

video{
  cursor: pointer;
}
.mnH42{
  min-height: 42px;
}
.minHeight65{
  min-height: 65px;
}

.minHeight75{
  min-height: 75px;
}
.minHeight80{
  min-height: 80px;
}
.minHeight90{
  min-height: 90px;
}
.minHeight85{
  min-height: 85px;
}
 
.minHeight400{
  min-height: 400px;
}
.minHeight300{
  min-height: 300px;
}
.minHeight200{
  min-height: 200px;
}
.minHeight150{
  min-height: 190px;
}
.minHeight100{
  min-height: 100px;
}
.minHeight110{
  min-height: 110px;
}

.dispBlock{
  display: block;
}
.ui-paginator .ui-paginator-page{
  display: inline-table !important;;
}

/* .new-ui-paginator .new-ui-paginator-page{
  display: inline-table !important;
} */

.w100p{
  width:100%;
}
.w140{
  width:140px;
}


.wth300{
  width:300px;
}
.wth200{
  width:200px;
}
.wth100{
  width:100px;
}
.wth120{
  width:110px;
}
.wth90{
  width:100px;
}
.wth110{
  width:110px;
}
.wth50{
  width:50px;
}
.wth70{
  width:70px;
}
.wth140{
  width:140px;
}
.wth250{
  width:250px;
}

#Clinics .ui-table .ui-sortable-column .ui-sortable-column-icon,#LocalOppourtunies  .ui-table .ui-sortable-column .ui-sortable-column-icon{
  color:#fff !important;
}
.ui-sortable-column-icon{
  color:#000000;
}
#Clinics .ui-table .ui-table-thead>tr>th,#LocalOppourtunies .ui-table .ui-table-thead>tr>th{
text-decoration: underline;
}
.metrics th{
text-align: center !important
}
.drkBdr td, .drkBdr th{
  border: 1px solid #cbcdcd !important;
}

#colorPicker .ui-inputtext {
  width: 60px !important;
}


#scheduledClinics table {
  margin-bottom: 0px !important;
}

span.alignSpan {
  position: relative;
  top: 16px;
}


.dateInput .ui-inputtext {
  display: inline !important;
}


.dateInput .ui-calendar .ui-calendar-button {
  height: 75% !important;
  right: 1px !important;
}

.dispNone{
  display: none !important;
}

.btn-vBig
 {
    font-size: 18px !important;
    font-weight: bold !important;
    padding: 12px 24px !important;
    height: auto !important;
    background-color: #323264 ;
    color:#fff;
}

#footer.footer-light .copyright {
  background-color:  #f5f5f0 !important;
  color: #666 !important;
}

#footer1.footer-light .copyright {
  background-color: #E0E0E0 !important;
  color: #666 !important;
  padding:12px !important;
  font-size: 12px;
   display: block;
}

a:hover.btn-vBig {
  color: #fff !important;
}

.has-error .ui-timepicker  span {
  margin: 0px !important;
}

.form-control.ht40,select.ht40 {
height: 40px !important;
}


#scheduledClinics th{
font-size: 12px;
}
#scheduledClinics td,#scheduledClinics td select, #scheduledClinics td input{
  font-size: 12px !important;
  }
  #scheduledClinics  .ui-datepicker {
      width: 20em !important}

      #detailsSchMenu .ui-dialog {
        left: 65% !important;
      } 

      #btnGroupDrop1.btn-group {
        width:100% !important;
        display: block !important;
      }

      #btnGroupDrop1 .dropdown-menu {
        z-index: 1009 !important;
    }
      
    .ui-table .ui-table-tbody > tr.ui-state-highlight, #scheduledClinics .ui-table .ui-table-tbody > tr.ui-state-highlight,.ui-chkbox-box.ui-state-active{
    background-color: transparent !important;
    color: #222222  !important;
  } 

  .customfileControl {
    display: block;
    border: 1px solid #c6c6c6;
    padding: 5px 10px;
    height: 40px;
    border-radius: 4px;
	width: 100%;
}

.ui-datatable .ui-datatable-thead>tr>th, .ui-datatable .ui-datatable-tfoot>tr>td, .ui-datatable .ui-datatable-data>tr>td{
  vertical-align: top;
}

.blueAnchor{
  color:blue !important;
}

.blueAnchor:hover{
  color:rgb(28, 28, 71) !important;
}
p.blueAnchor1 a{
  color:blue !important;
}

p.blueAnchor2 a{
  color:#007bff !important;
}

/* p.blueAnchor2 a:hover{
  color:rgb(28, 28, 71) !important;
} */
p.blueAnchor2 a:hover{
  color:#007bff !important;
}
/* p.blueAnchor2 a:focus{
  border:1px solid #003366 !important;
} */
.hideMeOutOfPrint{
  display: none;
}
.disableThisTr td,.disableThisTr select,.disableThisTr input{
color:#D5D5D5 !important;
}

.ui-state-highlight a{
  color:#222222;
}

.ui-chkbox-box.ui-widget.ui-state-default {
  border: 1px solid #555555;
}


.checkbox input[disabled="true"] + i { 
  border-color: #c6c6c6 !important;
}

.checkbox  input[disabled="true"] + i:after {
  color: #c6c6c6 !important;
}

/* #scheduledTrackerClinics tr th:nth-child(1){
  width:16px !important;
} */
#scheduledTrackerClinics tr th:nth-child(2){
    width:110px;
}
#scheduledTrackerClinics tr th:nth-child(3){
  width:164px;
}
#scheduledTrackerClinics tr th:nth-child(4){
  width:75px;
}
#scheduledTrackerClinics tr th:nth-child(5){
  width:65px;
}
#scheduledTrackerClinics tr th:nth-child(6){
  width:52px;
}
#scheduledTrackerClinics tr th:nth-child(7):last-child{
  width:60px;
}
#scheduledTrackerClinics tr th:nth-child(7):not(:last-child){
  width:120px;
}
#scheduledTrackerClinics tr th:nth-child(8),#scheduledTrackerClinics tr th:nth-child(9){
  width:80px;
}

.trackerApptDisabled{
  color: #c4c3c3 !important;
}

.trackerApptEnabled{
  color:#222222
}

.showTrackerGridCheckbox{
  width:0px !important;
  padding:0px !important;
}

.nav-link.active.dark{
  color:black !important;
  background-image: linear-gradient(#f5f5f5, #ffffff);
  font-size: 13px;
}

.myLoadingFont .visible.loading-text, .myLoadingFont #loading-spinner-text{
  font-size:16px !important;
  padding-top: 30% !important;
}

.extraDrkBdr{
border: 1px solid black;
}
.extraDrkBdr-right{
  border-right: 1px solid black;
}
.extraDrkBdr-left{
  border-left: 1px solid black;
}
.table-borderless td, .table-borderless th{
  border:none !important;
}

.fntSize135 p{
  font-size: 13.5px !important;
}

#asolulaContract .table>tbody>tr>td, #asolulaContract .table>tbody>tr>th, #asolulaContract .table>tfoot>tr>td, #asolulaContract .table>tfoot>tr>th, #asolulaContract .table>thead>tr>td, #asolulaContract .table>thead>tr>th{
  vertical-align: top !important;
}

#asolulaContract ol li, .limb20 ol li{
  margin-bottom: 20px;
  text-align: justify;
}

.scrollableTbl th, scrollableTbl td{
  width:200px !important;
}
.ui-calendar .ui-datepicker{
  z-index: 99 !important;
}

.signatureFont{
  font-family: 'La Belle Aurore';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
}

.inputSignFont{
  font-family: 'La Belle Aurore' !important;
  font-size:18px !important;
}
.btn[disabled]{
cursor: default !important;
}

.bgprimary {
  color: #fff;
  background-color: #2a7db5 !important;
}
.w150TWrap {
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
}
.f16{
  font-size: 16px;
}
.redTxt{
  color:red;
}
.alrdApprd{
overflow-wrap: break-word;
}

.ui-inputtext[disabled] {
  background-color: #e9ecef !important;
  opacity: 1 !important;
color: #495057 !important;
}

p-dialog .ui-dialog {
  max-width: 500px;
}

.mt-300{
  margin-top:300px;
}
.breakWordinMob{
  word-break: break-all;
}

.ui-table .ui-table-thead>tr>th {
  padding: .9em 0.5em ;
  font-size: 13px !important;
  cursor: pointer !important;
}

.p-datatable .p-datatable-thead>tr>th {
  padding: .9em 0.5em ;
  font-size: 14px !important;
  cursor: pointer !important;
}

.pd5th > th{
  padding-left: 5px !important;
}

.pcaSampleScript ul li,.pcaSampleScript ol li{
margin-bottom: 10px;
}

.pcaSampleScript ul{
  margin-top: 10px;
  }
  
.pcaSampleScript h3{
  margin-top: 20px;
  }
   .uAw{
    width:90px !important;
  }

  .lMargin .ui-autocomplete-loader, .clFld.lMargin i.fa-close {
    right: 20px !important;
}

.w20{
  width:20px;
}

.w50{
  width:50px;
}

.w60{
  width:60px;
}

.w70{
  width:70px;
}

.w80{
  width:80px;
}

.w90{
  width:90px;
}

.w100{
  width:130px;
}
.w10{
  width:10px;
}
.w120{
  width:120px;
}
.w160{
  width:160px;
}
.w150{
  width:150px;
}
.w155{
  width:155px;
}
.w200{
  width:200px;
}
.w250{
  width:250px;
}
.w300{
  width:300px;
}
.w350{
  width:350px;
}
.w400{
  width:400px;
}
.w40{
  width:40px;
}
.w25{
  width:25px;
}
.w480{
  width:480px;
}
.w86{
  width:49px;
}

.w115{
  width: 115px;
}

.w75{
  width: 75px;
}

#userProfileAutoCmplt .ui-autocomplete-panel.ui-widget-content.ui-corner-all.ui-shadow{
  display: none !important;
}

#tableUsers th.ui-state-active{
  background: #dfe0e2 !important;
}

#tableUsers .ui-sortable-column-icon, #tableUsers th.ui-state-active{
  color:#000000 !important;
}
#tableUsers .ui-datatable-resizable{
       overflow: visible !important;
}

#tableUsers .fa-bars{
cursor: pointer;
}

#tableUsers th {padding-left: 5px;}

#tableUsers .ui-sortable-column.ui-state-highlight {
  background-color: #dfe0e2 !important;
  color: #000 !important;
}

#scheduledTrackerClinics .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color:#000 !important;
}

.smallWeightFont{
  font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    margin-top: -18px;
    padding: 0;
}
#tblAppointmentReport .ui-sortable-column-icon {
  color:#000000 !important;
}

.btn-outline-primary {
  color: #2a7db5;
  border-color: #2a7db5;
}

.btn-outline-success {
  color: #7dc546 !important;
  background: transparent !important;
  border-color: #7dc546 !important;
}
.mh220{
min-height: 220px;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #2a7db5;
  border-color: #2a7db5;
}

.text-danger span.ui-dialog-title {
  color: red !important;
}

.ui-datatable-thead .ui-sortable-column-icon {
  color: #000000 !important;
}

.ui-datatable-thead .ui-state-active .ui-sortable-column-icon {
  color: #ffffff !important;
}

table tr td {
  word-wrap: break-word;
}
.w70p {
  width:70% !important;
}


#updateMode .radio+.radio {
  margin-top: 4px;
}



/* Customize the label (the checkboxcontainer) */
.checkboxcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -5px;
  left: 40px;
  height: 22px;
  width: 22px;
  /*background-color: #eee;*/
  border: 1px solid #c3c3c3;
}

.posRlBt20{
  position: relative;
  bottom: 20px;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxcontainer .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.overW86{
  width: 30px;
  padding-left: 10px;
} 

.mn230{
  min-height: 230px;
}

.uploderTable{
  min-height: 193px !important;
}

.i10{
  padding-left: 10px;
}

.inopad{
  padding-left: 0px;
}

.uploderTable .ui-table-scrollable-wrapper, .uploderTable.ui-table.ui-widget{
    min-height: 230px !important;
}
.wb{
  word-break: break-all;
}
.noFilesList{
  font-size: 16px;
  text-align: center;
}
.textAlignCenter{
  text-align: center;
}

p-dropdown .ui-dropdown.ui-widget.ui-state-default{
  border: none;
}
.noBdrTbl table {
  margin-bottom: 0px;
}

.mt-23{
  margin-top:23px;
}
.mt-28 {
  margin-top: 28px;
}

.clrDNCsearch{
  position: absolute;
  right: 60px;
  top: 108px;
}
.overflow-auto{overflow: auto;
}
.w80px{
  width:80px;
}
.ui-table table, .ui-datatable-scrollable-header-box table, .ui-datatable-scrollable-table-wrapper table{
margin-bottom: 0px;
}
.rp5{
right:15px;
}
.excludeVourcher{
  font-size:10px;
  bottom:10px;
}
.bt12{
  bottom: 12px;
}


.twoColmns{
  /* Old Chrome, Safari, Opera */
  -webkit-column-count: 2; 
  -webkit-column-width: 100px;
  /* Old Firefox */
  -moz-column-count: 2;
  -moz-column-width: 100px;
  /* Statndard syntax */
  column-count: 2;
  column-width: 100px;
  height: auto;
}

.doubleCheckBox .checkbox input{
display: none;
}

/* .doubleCheckBox .checkbox{
  line-height: 10px !important;
} */

.doubleCheckBox .checkbox i {
  position: absolute;
   top: 0px; 
  left: 0px;
}

.doubleCheckBox label{
  width:100%;
}



.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid gray;
}
.styled-checkbox:checked + label:before {
  background: white;
}
.styled-checkbox:disabled + label {
  color: gray;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: black;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
  transform: rotate(45deg);
}
.disablePaginator{
  pointer-events: none;
  color: #d4d1d1 !important;
}
.disablePaginator .ui-paginator-element{
  opacity: 0.4 !important;
}

div div > .hideParent {
  display:none;
}

.card.cardHeaderPurpleBg .radio i, .card.cardHeaderPurpleBg .checkbox i{
  background-color: #ffffff !important;
  }

  
  .clLinks, .clLinks:hover, .noHover, .noHover:hover {
    color: #007bff !important;
    text-decoration: underline !important;
  }
   .has-error.has-feedback label {
      width: 100% !important;
  }

  .charts h3,.charts h5 {
      margin-bottom: 10px;
  }

.toLineLabel {
    font-size: 10px;
    bottom: 6px;
    left: 31px;
}
  
  .DasbhaordHr{
    background: #ebebeb;
    margin-top:0px;
  }
  .mt-8 {
    margin-top: 8px !important;
}
.tblInPopup td{
border: none !important;
}
label.radio.other{
  width:auto !important;
}


.fw-100{
  font-weight: 400;
}

#storeHomeTabs .ui-state-active{
border-top: 4px solid #2a7db5;
font-weight: 600;

}

#storeHomeTabs .badge {
  font-size: 11px;
  position: relative;
    top: -2px;
    margin-left: 8px;
    padding:4px;
}

#storeHomeTabs .ui-state-default.ui-corner-top a{
font-size:15px;
}


#storeHomeTabs label.ui-multiselect-label.ui-corner-all {
margin-bottom: 0;
height: 24px;
}

#storeHomeTabs .ui-tabview.ui-tabview-top>.ui-tabview-nav {
  padding: .2em .2em 0 0;
  border-bottom: 1px solid lightgray;
  }

#storeHomeTabs .ui-multiselect{
  display:block;
}

#storeHomeTabs .ui-multiselect-panel .ui-multiselect-item label{
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 12px !important;
    font-weight: 100 !important; 
    font-size: 12px !important;
}

#storeHomeTabs .ui-multiselect-label.ui-corner-all{
  font-size: 12px !important;
  font-weight: 400 !important; 
  font-size: 12px !important;
}


#storeHomeTabs .ui-chkbox-box.ui-widget.ui-corner-all.ui-state-default.ui-state-active:first-child{
  color: #222222 !important;
  border: 1px solid #222222 !important;

}

.multiSelectOptionslabel{
  font-weight: 100 !important; 
  font-size: 12px !important;
}

#storeHomeTabs  .ui-multiselect .ui-multiselect-label {
   padding: 3px 2em .25em .25em;
}

#storeHomeTabs .ui-state-highlight {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #212121 !important;
}


#storeHomeTabs .ui-tabview .ui-tabview-nav > li.ui-state-active, #storeHomeTabs .ui-tabview.ui-tabview-top>.ui-tabview-nav li{
  height: 36px;
}

.badge.badge-light.bl{
  color:#2a7db5  !important;
  margin-left:10px;
}


#storeHomeTabs .ui-tabview .ui-tabview-panel{
  padding:0;
}

#programSelectDropDown .ui-dropdown{
width:100% !important;
}

#programSelectDropDown .ui-dropdown-items-wrapper{
  width: 280px;
}

#programSelectDropDown .ui-dropdown-label.ui-inputtext.ui-corner-all {
  padding: 3px !important;
  font-size: 13px !important;
}

#programSelectDropDown  .ui-inputtext {
  height: 26px;
}

.pr25px{
  padding-right:25px !important;
}


.wordBreakTDs td{ 
  word-break: break-word;
}

.w60px{
  width:60px;
}

.logoImg{
  height:65% !important;
}

.logoText{
  font-size: 1.571em;
  font-family: Arial,sans-serif,"Helvetica Neue",Helvetica;
  line-height: 64px;
  margin-left:10px;
}

.subTitle {
  font-size: 22px;
  font-weight: 300;
}

.leadGenerationForm .form-control {
height: 33px !important;
}

.leadGenerationForm label{
  color:#404040;
    font-weight: 600;
}
.leadGenerationForm .form-group{
margin-bottom: 25px !important;
}


.leadGenerationForm .checkbox+.checkbox, .leadGenerationForm .radio+.radio {
   margin-top: 0 !important; 
}

.leadGenerationForm  .radio{
  line-height: 20px !important; 
}

.icons.has-error.has-feedback i{
  border: 1px solid red !important;
  color:red !important;
}


.has-error.has-feedback ~ .chkBox label{
  color:red !important;
}



.ldColor a{
  color:#01728e;
}
.has-error.has-feedback  label:before{
  border: 1px solid red !important;
}



.honeypot{
  display: none;
}
.leadGenerationForm label span{
font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.leadGenerationForm label .lspan:last-child{
 display: inline-block;
  position: absolute;
}

.has-error#lgCntrlepyTtnemyap294 span {
  margin: 0 !important;
}
.imzCallScript ol li:first-child{
  margin-top: 10px;
  }

.imzCallScript ol li{
  margin-bottom: 10px;
  }
  @media only screen and (max-width: 768px)  {
    .btn-vBig {
        width: 100%;
        font-size: 12px!important;
    }
  }
  #leadGenerationId .chkBox label:focus, #leadGenerationId .chkBox label:active{
    border: 1px solid #003366 !important;
  }
  #leadGenerationId input[type=checkbox]:focus + label:before,#leadGenerationId input[type=checkbox]:active{
    
    border: 1px solid #003366 !important;
  } 
  #leadGenerationId input[type=radio]:focus + label:before, #leadGenerationId input[type=radio]:active{
    border: 1px solid #003366 !important;
  }
  #leadGenerationId p-calendar .ui-inputtext:focus{
    border: 1px solid #003366 !important;
  }
  #leadGenerationId  p-inputmask .ui-inputtext:focus{
    border: 1px solid #003366 !important;
  }

  #leadGenerationId input:focus + i {
    border: 1px solid #003366 !important;
}
#scheduledTrackerClinics .ui-paginator .ui-paginator-page.ui-state-active, #tblAppointmentReport  .ui-paginator .ui-paginator-page.ui-state-active {
  background: #323264 !important;
  color: #ffffff !important;
  border-color: #323264 !important;
}
.customtblStriped tr:nth-child(odd) {
  background-color: #ece7e7 !important;

}
#hintCheckBox .ui-inputswitch.ui-inputswitch-checked::after {
  transition: color 0.5s;
  font-size: 0.9em;
  color: #fff !important;
  content: 'OFF';
  position: absolute;
  left: 6px;
  top: 3px;
}

#hintCheckBox .ui-inputswitch:not(.ui-inputswitch-checked)::after {
  transition: color 0.5s;
  font-size: 0.9em;
  color: #fff !important;
  content: 'ON';
  position: absolute;
  left: 28px;
  top: 3px;
}

#hintCheckBox .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
  background-color: #fff !important;
  height: 1.50em;
  width: 1.25em;
  left: 1.40em;
  bottom: 0.12em;
  border-radius: 10%;
  transition: 0.2s;
}

#hintCheckBox .ui-inputswitch:not(.ui-inputswitch-checked) .ui-inputswitch-slider:before {
  background-color: #fff !important;
  height: 1.50em;
  width: 1.25em;
  left: .14em;
  bottom: 0.12em;
  border-radius: 10% !important;
  transition: 0.2s;
}

#hintCheckBox .ui-inputswitch:not(.ui-inputswitch-checked) .ui-inputswitch-slider {
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  background: #007ad9;
}

#hintCheckBox .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: #007ad9;
  border-radius: 3px;
}
#HintDlg .ui-widget-header, .firstHintDlg .ui-widget-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.firstHintDlg .ui-dialog.ui-widget .ui-dialog-titlebar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.ui-tabview-nav li{
  border-bottom: none !important;
  border-bottom-width: 0px !important;
}
.ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active{
  border-color:silver !important;
}
.ui-tabview .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover{
  border-color:silver;
}

.ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover{
  border-color: transparent !important;
}
.ui-tabview .ui-tabview-panels{
  border:none !important;
}

p-calendar input{
  display: block !important;
  width: 100%;
  height: 30px;
  font-size: 12px !important;
  line-height: 1.5 !important; 
  color: #495057 !important;
  background-color: #fff;
  background-image: none !important;
  background-clip: padding-box !important;
   border-radius: .25rem !important;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s !important;
  border: #c6c6c6 1px solid !important;
  box-shadow: none !important;
  border-radius: 3px !important; 
  padding: 0px 10px !important;
  font-size: 14px !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #f4f4f4!important;
  border-color: #c8c8c8!important;
  color: #ffffff;
}

.p-dialog .p-dialog-header{
  padding: 6px 6px 6px 20px!important;
}
.p-dialog .p-dialog-header .p-dialog-title
{
  font-weight: 400;
  font-size: 18px;
}
.p-dialog {
  /* width: 550px!important; */
  border:none!important;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
}

.p-tabview .p-tabview-panels {

  border: none!important;
 
}
#contractCompConf .p-dialog{
  max-width: 500px!important;
}
.ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #fff!important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 0.5rem;
}
#HintDlg .p-dialog .p-dialog-header {
  border-bottom: 0px!important;
 border-top: 4px solid #cf1737!important;
 color: #fff !important;
}
#HintDlg .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #cf1737;
  border-radius: 30px;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #186ba0;;
  color: #ffffff;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #186ba0;;
  color: #ffffff;
}
p-autocomplete input{
  width: 300px;
  height: 30px;
}
.mr2
{
  margin-right: 2px !important;
}
.p-datatable .p-datatable-tbody > tr:nth-child(2n)
{
  background-color:#fafafb !important;
}
.p-datatable .p-datatable-tbody > tr > td
{
  padding: .25em .5em;
}
.p-paginator .p-dropdown .p-dropdown-label
{
  border: 0px !important;
}
#dashboardPaginator .p-paginator.p-component
{
  display: block !important;
}
.p-component
{
  font-size: 1em;
}
.inputSignFont {
  font-family: La Belle Aurore!important;
   font-size: 18px!important;
 }
 #viewHistoryAcc .p-accordion .p-accordion-header .p-accordion-header-link {
  font-weight: 300;
  border-radius: 3px!important;
}
#viewHistoryAcc .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #dbdbdb!important; 
   border-color: #dbdbdb!important; 
  color: #333333!important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
#viewHistoryAcc > div,.ui-state-active:hover a,.ui-accordion-header a{
  /* background: #787878 !important; */
  border: none !important;
}
#droppedStoresTbl .p-datatable-scrollable-header-box
{
  padding-right: 0px !important;
}

.p-dialog .p-dialog-header{
  border-bottom:0px !important;
}
.p-dialog .p-dialog-footer
{
  border-top:0px !important;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #333333;
  padding: 1em 1.5em!important;
  font-size: 1em!important;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
  font-weight: 500!important;
}
.p-datatable-scrollable-header-table
{
margin-bottom: 0px !important;
}
.p-datatable-scrollable-body table
{
margin-bottom: 0px !important;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #d8dae2;
 padding: 0px!important;
}
.p-datepicker{
  padding: 0px!important;
}
 .has-error button, .has-error button{
  border: 0px solid !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1rem!important;
  width: 125px!important;
  padding: 0px!important;
}
#droppedStoresTbl .p-datatable .p-datatable-tbody > tr.p-highlight
{
  background: #ffffff;
  color: #333333;
}
#HintDlg .p-dialog .p-dialog-header {
  padding: 0px!important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #2a7db5!important;
  border-color: #2a7db5!important;
  color: #ffffff;
}
.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background:#2a7db5!important;
}
/* .btn-primary {
  color: #fff;
  background: #2a7db5!important;
  border-color: #2a7db5!important;
} */

.p-button {
  border: 1px solid #2a7db5 !important;
  color: #FFFFFF !important;
  background: #2a7db5 !important;
  -webkit-transition: background-color .2s;
  -moz-transition: background-color .2s;  
  padding: 0.39rem 1rem 0.39rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}
  /* .p-autocomplete .p-autocomplete-loader {
    display: none!important;
} */

section.page-header.corpStoreApptTrackerBannerColor
{
  background-color: #323264;
  color: #fff;
}

.payment-mb10
{
  margin-bottom:0px;
}

.inWitnessFont2022{
  text-align: center;
}
.vaTop{
  vertical-align: top !important;
}

.p-inline{
  display: inline;
}

.fontSize-100
{
  font-size: 100% !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link
{
  background:#2a7db5 !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link:hover
{
  background:#286dad !important;      
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.visually-hidden {
  display: none;
}

.p-element.p-ripple.p-dialog-header-icon.p-dialog-header-close.p-link.p-component:not(.dummy-class) {
  background-color: initial !important;
  border: none !important;
  color: #848484 !important;
}

.p-dialog-header-close-icon  :hover{
  color: #333333 !important; 
} 

.p-element.p-ripple.p-dialog-header-icon.p-dialog-header-close.p-link.p-component:hover{
  color: #333333 !important;
}

